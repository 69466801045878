<template>
  <div>
    <global-dimension-filter></global-dimension-filter>
    <shipping-status-report></shipping-status-report>
  </div>
</template>
<script>
import GlobalDimensionFilter from "../widgets/GlobalDimensionFilter.vue";
import ShippingStatusReport from "../widgets/ShippingStatusReport.vue";

export default {
  components: {
    GlobalDimensionFilter,
    ShippingStatusReport,
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>
