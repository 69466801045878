<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CButton
            tag="button"
            color="link"
            block
            class="text-left shadow-none card-header"
          >
            <v-card-text class="pa-0">
              <v-row class="pa-0">
                <v-col class="pa-0">
                  <v-card-title @click="collapseTable = !collapseTable">
                    <div class="d-flex align-center">
                      <h5 class="m-0 pr-4">Shipping Status Report</h5>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="#3780D9" v-bind="attrs" v-on="on"
                            >mdi-information-outline</v-icon
                          >
                        </template>
                        <span
                          >Date filter applies to {{ DATE_FIELDS.CREATION_DATE }}.</span
                        >
                      </v-tooltip>
                    </div>
                  </v-card-title>
                </v-col>
                <v-col class="py-0 d-flex">
                  <v-text-field
                    v-model="search"
                    label="Search"
                    append-icon="mdi-magnify"
                    clearable
                    :hide-details="true"
                  >
                  </v-text-field>
                  <v-btn
                    :disabled="isDownloading"
                    class="mt-3 ml-5 primary text-white"
                    style="width: 140px"
                    @click="loadData(true)"
                  >
                    <template v-if="isDownloading">
                      <v-progress-circular
                        indeterminate
                        :width="2"
                        :size="20"
                        color="primary"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      <v-icon class="mr-2">mdi-file-excel-outline</v-icon
                      >Download
                    </template>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </CButton>
          <CCollapse :show="collapseTable">
            <v-app>
              <CCardBody class="m-1">
                <loading-graph :isLoading="isLoading">
                  <template v-slot:content>
                    <CRow>
                      <CCol sm="12">
                        <v-data-table
                          dense
                          :search="search"
                          :items="data"
                          :items-per-page="50"
                          :footer-props="{ itemsPerPageOptions: [50, -1] }"
                          :headers="headers"
                          sort-by="date_shipped"
                          :sort-desc="true"
                        >
                          <template v-slot:item.quantity="item">
                            {{
                              item.item.quantity == null
                                ? ""
                                : item.item.quantity.toLocaleString("en-CA")
                            }}
                          </template>
                          <template v-slot:item.iu="item">
                            {{
                              item.item.iu == null
                                ? ""
                                : item.item.iu.toLocaleString("en-CA")
                            }}
                          </template>
                          <template v-slot:item.date_created="item">
                            {{ $moment(item.item.date_created).format("L") }}
                          </template>
                          <template v-slot:item.permit_receipt_date="item">
                            {{ $moment(item.item.permit_receipt_date).format("L") }}
                          </template>
                          <template v-slot:item.date_shipped="item">
                            {{ $moment(item.item.date_shipped).format("L") }}
                          </template>
                          <template v-slot:item.date_delivered="item">
                            {{ $moment(item.item.date_delivered).format("L") }}
                          </template>
                          <template v-slot:item.date_expiration="item">
                            {{ $moment(item.item.date_expiration).format("L") }}
                          </template>
                        </v-data-table>
                      </CCol>
                    </CRow>
                  </template>
                </loading-graph>
              </CCardBody>
            </v-app>
          </CCollapse>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CCard>
          <CButton
            tag="button"
            color="link"
            block
            class="text-left shadow-none card-header"
          >
            <v-card-text class="pa-0">
              <v-row class="pa-0">
                <v-col class="pa-0">
                  <v-card-title @click="collapseFinancialTable = !collapseFinancialTable">
                    <div class="d-flex align-center">
                      <h5 class="m-0 pr-4">Financial  Report</h5>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="#3780D9" v-bind="attrs" v-on="on"
                            >mdi-information-outline</v-icon
                          >
                        </template>
                        <span
                          >Date filter applies to {{ DATE_FIELDS.CREATION_DATE }}.</span
                        >
                      </v-tooltip>
                    </div>
                  </v-card-title>
                </v-col>
                <v-col class="py-0 d-flex">
                  <v-text-field
                    v-model="financialSearch"
                    label="Search"
                    append-icon="mdi-magnify"
                    clearable
                    :hide-details="true"
                  >
                  </v-text-field>
                  <v-btn
                    :disabled="isFinancialDownloading"
                    class="mt-3 ml-5 primary text-white"
                    style="width: 140px"
                    @click="loadFinancialData(true)"
                  >
                    <template v-if="isFinancialDownloading">
                      <v-progress-circular
                        indeterminate
                        :width="2"
                        :size="20"
                        color="primary"
                      ></v-progress-circular>
                    </template>
                    <template v-else>
                      <v-icon class="mr-2">mdi-file-excel-outline</v-icon>Download
                    </template>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </CButton>
          <CCollapse :show="collapseFinancialTable">
            <v-app>
              <CCardBody class="m-1">
                <loading-graph :isLoading="isLoading">
                  <template v-slot:content>
                    <CRow>
                      <CCol sm="12">
                        <v-data-table
                          dense
                          :search="financialSearch"
                          :items="financialData"
                          :items-per-page="50"
                          :footer-props="{ itemsPerPageOptions: [50, -1] }"
                          :headers="financialHeaders"
                          sort-by="date_shipped"
                          :sort-desc="true"
                        >
                          <template v-slot:item.estimatedCostPerVial="item">
                            {{
                              item.item.estimatedCostPerVial == null
                                ? "N/A"
                                : item.item.estimatedCostPerVial.toLocaleString("en-CA", {
                                    style: 'currency',
                                    currency: 'CAD',
                                  })
                            }}
                          </template>
                          <template v-slot:item.actualCostPerVial="item">
                            {{
                              item.item.actualCostPerVial == null
                                ? "N/A"
                                : item.item.actualCostPerVial.toLocaleString("en-CA", {
                                    style: 'currency',
                                    currency: 'CAD',
                                  })
                            }}
                          </template>
                          <template v-slot:item.date_created="item">
                            {{ $moment(item.item.date_created).format("L") }}
                          </template>
                          <template v-slot:item.date_shipped="item">
                            {{ $moment(item.item.date_shipped).format("L") }}
                          </template>
                          <template v-slot:item.date_delivered="item">
                            {{ $moment(item.item.date_delivered).format("L") }}
                          </template>
                          <template v-slot:item.date_expiration="item">
                            {{ $moment(item.item.date_expiration).format("L") }}
                          </template>
                        </v-data-table>
                      </CCol>
                    </CRow>
                  </template>
                </loading-graph>
              </CCardBody>
            </v-app>
          </CCollapse>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "../../plugins/axios";
import store from "../../store/index";
import { DATE_FIELDS } from "../../utils/const";
import LoadingGraph from "../../components/graph/LoadingGraph.vue";

export default {
  components: {
    LoadingGraph,
  },
  data() {
    return {
      isLoadingCount: 0,
      isDownloadingCount: 0,
      isFinancialDownloadingCount: 0,
      search: "",
      financialSearch: "",
      collapseTable: true,
      collapseFinancialTable: true,
      headers: [
        { text: "SALES ORDER ID", value: "salesOrderId" },
        { text: "COUNTRY", value: "country" },
        { text: "WAREHOUSE", value: "warehouse" },
        { text: "PRODUCT BRAND", value: "product_brand" },
        { text: "LOT", value: "lot" },
        { text: "QUANTITY", value: "quantity", align: "end" },
        { text: "IUs/MGs", value: "iu", align: "end" },
        { text: "INTERNAL STATUS", value: "internalStatus" },
        { text: "ORDER CREATION DATE", value: "date_created" },
        { text: "PERMIT RECEIPT DATE", value: "permit_receipt_date" },
        { text: "SHIPPING DATE", value: "date_shipped" },
        { text: "DELIVERY DATE", value: "date_delivered" },
        { text: "EXPIRATION DATE", value: "date_expiration" },
      ],
      financialHeaders: [
        { text: "SALES ORDER ID", value: "salesOrderId" },
        { text: "COUNTRY", value: "country" },
        { text: "WAREHOUSE", value: "warehouse" },
        { text: "SHIPPING PROVIDER", value: "shippingProvider" },
        { text: "ORDER CREATION DATE", value: "date_created" },
        { text: "SHIPPING DATE", value: "date_shipped" },
        { text: "DELIVERY DATE", value: "date_delivered" },
        { text: "ESTIMATED COST / VIALS", value: "estimatedCostPerVial", sort: (a, b) => this.customSort(a, b)},
        { text: "REAL COST / VIALS", value: "actualCostPerVial", sort: (a, b) => this.customSort(a, b)},
      ],
      data: [],
      financialData: []
    };
  },
  computed: {
    filters: {
      get() {
        return this.$store.getters["globalDimensionFilter/filters"];
      },
      set(value) {
        this.$store.commit("globalDimensionFilter/setFilters", value);
      },
    },
    formatedFilters() {
      return this.$store.getters["globalDimensionFilter/formatedFilters"];
    },
    onFilterChange() {
      return this.$store.getters["globalDimensionFilter/onFilterChange"];
    },
    isLoading() {
      return this.isLoadingCount > 0;
    },
    isDownloading() {
      return this.isDownloadingCount > 0;
    },
    isFinancialDownloading() {
      return this.isFinancialDownloadingCount > 0;
    },
  },
  watch: {
    onFilterChange: {
      handler() {
        this.loadData();
        this.loadFinancialData();
      },
      deep: true,
    },
  },
  methods: {
    customSort(a, b) {
      if (a == null)
        return -1;
      if (a == b)
        return 0;
      if (a < b)
        return -1;
      if (a > b)
        return +1;
      return 0;
    },
    loadData(isExcelExport = false) {
      if (isExcelExport) {
        this.isDownloadingCount++;
        axios
          .post(
            "shipmentinternalstatus?isExcelExport=true",
            this.formatedFilters,
            {
              signal: store.getters.abortSignal,
              responseType: "blob",
            }
          )
          .then((res) => {
            this.isDownloadingCount--;
            const blob = new Blob([res.data], { type: res.data.type });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.setAttribute("download", "Shipping Status Report.xlsx");
            link.click();
          })
          .catch((e) => {
            this.isDownloadingCount--;
            if (!e.message == "canceled") reject(e);
          });
      } else {
        this.isLoadingCount++;
        axios
          .post("shipmentinternalstatus", this.formatedFilters, {
            signal: store.getters.abortSignal,
          })
          .then((res) => {
            this.isLoadingCount--;
            this.data = res.data;
          })
          .catch((e) => {
            this.isLoadingCount--;
            if (!e.message == "canceled") return Promise.reject(e);
          });
      }
    },
    loadFinancialData(isExcelExport = false) {
      if (isExcelExport) {
        this.isFinancialDownloadingCount++;
        axios
          .post(
            "shipmentinternalstatus/financial?isExcelExport=true",
            this.formatedFilters,
            {
              signal: store.getters.abortSignal,
              responseType: "blob",
            }
          )
          .then((res) => {
            this.isFinancialDownloadingCount--;
            const blob = new Blob([res.data], { type: res.data.type });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.setAttribute("download", "Financial Report.xlsx");
            link.click();
          })
          .catch((e) => {
            this.isFinancialDownloadingCount--;
            if (!e.message == "canceled") reject(e);
          });
      } else {
        this.isLoadingCount++;
        axios
          .post("shipmentinternalstatus/financial", this.formatedFilters, {
            signal: store.getters.abortSignal,
          })
          .then((res) => {
            this.isLoadingCount--;
            this.financialData = res.data;
          })
          .catch((e) => {
            this.isLoadingCount--;
            if (!e.message == "canceled") return Promise.reject(e);
          });
      }
    },
  },
  created() {
    this.DATE_FIELDS = DATE_FIELDS;
    this.loadData();
    this.loadFinancialData();
  },
};
</script>
