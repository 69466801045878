<template>
  <div
    v-if="isLoading"
    :style="
      'height: ' +
      height +
      '; min-height: ' +
      minHeight +
      '; position: relative' +
      (fullHeight ? '; height: 100%' : '')
    "
  >
    <v-progress-circular
      :style="isCentered ? 'position: absolute; top: 48%; left: 48%' : ''"
      :size="size"
      :color="this.$store.getters['generalData/backgroundColors'][0]"
      indeterminate
    ></v-progress-circular>
  </div>
  <div :style="fullHeight ? 'height: 100%' : ''" v-else>
    <slot name="content"></slot>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "LoadingGraph",
  props: {
    isLoading: Boolean,
    isCentered: {
      type: Boolean,
      default: true,
    },
    minHeight: {
      type: String,
      default: "400px",
    },
    height: {
      type: String,
      default: "100%",
    },
    size: {
      type: Number,
      default: 50,
    },
    fullHeight: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
});
</script>
