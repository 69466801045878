<template>
  <v-expansion-panels class="pb-5" v-model="collapseFilters">
    <v-expansion-panel class="filter">
      <v-expansion-panel-header>
        <h5>Filters</h5>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-divider></v-divider>
        <v-row class="d-flex align-center justify-start">
          <v-col cols="2">
            <v-autocomplete
              v-model="filters.manufacturers"
              :items="manufacturers"
              :readonly="manufacturerUser !== 'null'"
              label="Manufacturer"
              item-text="name"
              multiple
              hide-details
              :loading="isFilterLoading"
              :disabled="isFilterLoading"
              class="secondary"
              @change="filterChanged = true"
              return-object
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  small
                  v-bind="attrs"
                  :input-value="selected"
                  @click="select"
                  @click:close="removeManufacturers(item)"
                  :close="manufacturerUser === 'null'"
                  :class="
                    manufacturerUser === 'null' ? 'overflow-ellipsis' : ''
                  "
                  text-color="white"
                  class="secondary"
                  >{{ item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              v-model="filters.productBrands"
              :items="productBrands"
              item-text="name"
              item-value="name"
              label="Product brand"
              multiple
              hide-details
              :loading="isFilterLoading"
              :disabled="isFilterLoading"
              class="secondary"
              @change="filterChanged = true"
              return-object
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  small
                  v-bind="attrs"
                  :input-value="selected"
                  @click="select"
                  @click:close="removeProductBrands(item)"
                  close
                  text-color="white"
                  class="secondary overflow-ellipsis"
                  >{{ item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              v-model="filters.factorTypes"
              :items="factorTypes"
              label="Product Type"
              hide-details
              item-text="name"
              item-value="name"
              multiple
              :loading="isFilterLoading"
              :disabled="isFilterLoading"
              class="secondary"
              @change="filterChanged = true"
              return-object
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  small
                  v-bind="attrs"
                  :input-value="selected"
                  @click="select"
                  @click:close="removeFactorTypes(item)"
                  close
                  text-color="white"
                  class="secondary overflow-ellipsis"
                  >{{ item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <!-- <v-col v-if="isWarehouses"> -->
          <v-col cols="2">
            <v-autocomplete
              v-model="filters.warehouses"
              :items="warehouses"
              label="Warehouse"
              item-text="name"
              item-value="name"
              hide-details=""
              :loading="isFilterLoading"
              :disabled="isFilterLoading"
              multiple
              class="secondary"
              @change="filterChanged = true"
              return-object
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  small
                  v-bind="attrs"
                  :input-value="selected"
                  @click="select"
                  @click:close="removeWarehouses(item)"
                  close
                  text-color="white"
                  class="secondary overflow-ellipsis"
                  >{{ item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <!-- <v-col v-if="isRegion"> -->
          <v-col cols="2">
            <v-autocomplete
              v-model="filters.region"
              :items="regions"
              label="Region"
              item-text="name"
              item-value="name"
              multiple
              hide-details
              :loading="isFilterLoading"
              :disabled="isFilterLoading"
              class="secondary"
              @change="filterChanged = true"
              return-object
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  small
                  v-bind="attrs"
                  :input-value="selected"
                  @click="select"
                  @click:close="removeRegions(item)"
                  close
                  text-color="white"
                  class="secondary overflow-ellipsis"
                  >{{ item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <!-- <v-col v-if="isCountry"> -->
          <v-col cols="2">
            <v-autocomplete
              v-model="filters.country"
              :items="countries"
              label="Country"
              item-text="name"
              item-value="name"
              multiple
              hide-details
              :loading="isFilterLoading"
              :disabled="isFilterLoading"
              class="secondary"
              @change="filterChanged = true"
              return-object
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  small
                  v-bind="attrs"
                  :input-value="selected"
                  @click="select"
                  @click:close="removeCountries(item)"
                  close
                  text-color="white"
                  class="secondary overflow-ellipsis"
                  >{{ item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              v-model="filters.lotNumbers"
              :items="lotNumbers"
              label="Lot Numbers"
              item-text="name"
              item-value="name"
              hide-details=""
              :loading="isFilterLoading"
              :disabled="isFilterLoading"
              multiple
              class="secondary"
              @change="filterChanged = true"
              return-object
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  small
                  v-bind="attrs"
                  :input-value="selected"
                  @click="select"
                  @click:close="removeLotNumbers(item)"
                  close
                  text-color="white"
                  class="secondary overflow-ellipsis"
                  >{{ item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col v-if="isCountryReportCard" cols="2">
            <v-text-field
              v-model="filters.ageFrom"
              label="Start Age"
              type="number"
              hide-details
              class="secondary"
              :loading="isFilterLoading"
              :disabled="isFilterLoading"
              @change="filterChanged = true"
            >
            </v-text-field>
          </v-col>
          <v-col v-if="isCountryReportCard" cols="2">
            <v-text-field
              v-model="filters.ageTo"
              label="End Age"
              type="number"
              hide-details
              class="secondary"
              :loading="isFilterLoading"
              :disabled="isFilterLoading"
              @change="filterChanged = true"
            >
            </v-text-field>
          </v-col>
          <v-col v-if="isCountryReportCard" cols="2">
            <v-select
              v-model="filters.withProphylaxis"
              :items="prophylaxisStatus"
              label="Prophylaxis Status"
              item-text="name"
              item-value="value"
              hide-details
              :loading="isFilterLoading"
              :disabled="isFilterLoading"
              class="secondary"
              @change="filterChanged = true"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  small
                  v-bind="attrs"
                  :input-value="selected"
                  @click="select"
                  @click:close="removeProphylaxisStatus()"
                  close
                  text-color="white"
                  class="secondary overflow-ellipsis"
                  >{{ item.name }}
                </v-chip>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <!-- TOGGLE SECTION -->
        <!-- <v-row
          class="d-inline-flex flex-row justify-start pt-0"
          v-if="isDateToggle"
        > -->
        <v-row class="d-inline-flex flex-row justify-start pt-4">
          <v-col class="pb-0">
            <v-btn-toggle
              v-model="filters.dateFilterToggle"
              dense
              mandatory
              :loading="isFilterLoading"
              :disabled="isFilterLoading"
              class="secondary"
            >
              <v-btn outlined small color="secondary">Year</v-btn>
              <v-btn outlined small color="secondary">Month</v-btn>
              <v-btn outlined small color="secondary">Quarter</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col>
            <v-select
              v-model="filters.dateFrom.year"
              dense
              clearable
              :loading="isFilterLoading"
              :disabled="filters.dateFilterToggle == null || isFilterLoading"
              :items="years"
              label="Year from"
              class="secondary"
              hide-details
              @change="filterChanged = true"
            ></v-select>
          </v-col>
          <v-col v-if="filters.dateFilterToggle == 1">
            <v-select
              v-model="filters.dateFrom.month"
              dense
              clearable
              :loading="isFilterLoading"
              :disabled="!filters.dateFrom.year || isFilterLoading"
              :items="months"
              item-text="label"
              item-value="value"
              label="Month"
              class="secondary"
              hide-details
              @change="filterChanged = true"
            ></v-select>
          </v-col>
          <v-col v-if="filters.dateFilterToggle == 2">
            <v-select
              v-model="filters.dateFrom.quarter"
              dense
              clearable
              :loading="isFilterLoading"
              :disabled="!filters.dateFrom.year || isFilterLoading"
              :items="quarters"
              item-text="label"
              item-value="value"
              label="Quarter"
              class="secondary"
              hide-details
              @change="filterChanged = true"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              v-model="filters.dateTo.year"
              dense
              clearable
              :loading="isFilterLoading"
              :items="years"
              :disabled="filters.dateFilterToggle == null || isFilterLoading"
              label="Year To"
              class="secondary"
              hide-details
              @change="filterChanged = true"
            ></v-select>
          </v-col>
          <v-col v-if="filters.dateFilterToggle == 1">
            <v-select
              v-model="filters.dateTo.month"
              dense
              clearable
              :loading="isFilterLoading"
              :disabled="!filters.dateTo.year"
              :items="months"
              item-text="label"
              item-value="value"
              label="Month"
              class="secondary"
              hide-details
              @change="filterChanged = true"
            ></v-select>
          </v-col>
          <v-col v-if="filters.dateFilterToggle == 2">
            <v-select
              v-model="filters.dateTo.quarter"
              dense
              clearable
              :loading="isFilterLoading"
              :disabled="!filters.dateTo.year || isFilterLoading"
              :items="quarters"
              item-text="label"
              item-value="value"
              label="Quarter"
              class="secondary"
              hide-details
              @change="filterChanged = true"
            ></v-select>
          </v-col>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="#3780D9" v-bind="attrs" v-on="on"
                >mdi-information-outline</v-icon
              >
            </template>
            <span
              >Filter applies to different dates depending on the graph. See
              graph information for more details.</span
            >
          </v-tooltip>
        </v-row>
        <!-- END TOGGLE SECTION -->

        <br />
        <v-row class="pa-0 ma-0 d-flex flex-row-reverse">
          <v-btn
            color="primary"
            outlined
            elevation="0"
            @click="resetFilters()"
            class=""
            :disabled="!filterChanged"
            >Reset Filters
          </v-btn>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { ROUTE_NAMES } from "../../utils/const";

export default {
  props: [
    "isManufacturers",
    "isProductBrands",
    "isFactorTypes",
    "isWarehouses",
    "isRegion",
    "isCountry",
    "isMonth",
    "isQuarter",
    "isYear",
    "isDateToggle",
    "isDateFromOnly",
  ],
  data() {
    return {
      isCountryReportCard: false,
    };
  },
  watch: {
    filters: {
      handler(value) {
        sessionStorage.setItem("dimensionFilters", JSON.stringify(value));
        this.$store.commit("globalDimensionFilter/setOnFilterChange");
        this.$store.dispatch("abort");
        this.$store.dispatch("globalDimensionFilter/loadGlobalFilter");
      },
      deep: true,
    },

    isFilterLoading: {
      handler(value) {
        if (value) {
        }
      },
      deep: true,
    },
  },
  computed: {
    filters: {
      get() {
        return this.$store.getters["globalDimensionFilter/filters"];
      },
      set(value) {
        this.$store.commit("globalDimensionFilter/setFilters", value);
      },
    },
    collapseFilters: {
      get() {
        return this.$store.getters["globalDimensionFilter/collapseFilters"];
      },
      set(value) {
        this.$store.commit("globalDimensionFilter/setCollapseFilters", value);
      },
    },
    filterChanged: {
      get() {
        return this.$store.getters["globalDimensionFilter/filterChanged"];
      },
      set(value) {
        if (value == true) this.$store.dispatch("abort");
        this.$store.commit("globalDimensionFilter/setFilterChanged", value);
      },
    },
    isFilterLoading() {
      return this.$store.getters["globalDimensionFilter/isFilterLoading"];
    },
    factorTypes() {
      return this.$store.getters["globalDimensionFilter/factorTypes"];
    },
    manufacturers() {
      return this.$store.getters["globalDimensionFilter/manufacturers"] ?? [];
    },
    manufacturerUser() {
      return this.$store.getters["auth/getLoggedInUserOrgId"];
    },
    months() {
      return this.$store.getters["globalDimensionFilter/months"];
    },
    productBrands() {
      return this.$store.getters["globalDimensionFilter/productBrands"];
    },
    quarters() {
      return this.$store.getters["globalDimensionFilter/quarters"];
    },
    warehouses() {
      return this.$store.getters["globalDimensionFilter/warehouses"];
    },
    lotNumbers() {
      return this.$store.getters["globalDimensionFilter/lotNumbers"];
    },
    years() {
      return this.$store.getters["globalDimensionFilter/years"];
    },
    regions() {
      return this.$store.getters["globalDimensionFilter/regions"];
    },
    countries() {
      return this.$store.getters["globalDimensionFilter/countries"];
    },
    prophylaxisStatus() {
      return this.$store.getters["globalDimensionFilter/prophylaxisStatus"];
    },
  },
  mounted() {
    this.setGlobalFilterManufacturer();
  },
  methods: {
    removeManufacturers(item) {
      for (var i = 0; i < this.filters.manufacturers.length; i++) {
        if (
          this.filters.manufacturers[i].id == item.id &&
          this.filters.manufacturers[i].name == item.name
        ) {
          this.filters.manufacturers.splice(i, 1);
          continue;
        }
      }
    },
    setGlobalFilterManufacturer() {
      if (
        this.manufacturers.length == 1 &&
        this.filters.manufacturers.length === 0
      ) {
        this.filters.manufacturers.push(this.manufacturers[0]);
      }
    },
    removeProductBrands(item) {
      // this.filters.productBrands.splice(
      //   this.filters.productBrands.indexOf(item),
      //   1
      // );
      for (var i = 0; i < this.filters.productBrands.length; i++) {
        if (
          this.filters.productBrands[i].id == item.id &&
          this.filters.productBrands[i].name == item.name
        ) {
          this.filters.productBrands.splice(i, 1);
          continue;
        }
      }
    },
    removeFactorTypes(item) {
      // this.filters.factorTypes.splice(
      //   this.filters.factorTypes.indexOf(item),
      //   1
      // );
      for (var i = 0; i < this.filters.factorTypes.length; i++) {
        if (
          this.filters.factorTypes[i].id == item.id &&
          this.filters.factorTypes[i].name == item.name
        ) {
          this.filters.factorTypes.splice(i, 1);
          continue;
        }
      }
    },
    removeWarehouses(item) {
      //this.filters.warehouses.splice(this.filters.warehouses.indexOf(item), 1);
      for (var i = 0; i < this.filters.warehouses.length; i++) {
        if (
          this.filters.warehouses[i].id == item.id &&
          this.filters.warehouses[i].name == item.name
        ) {
          this.filters.warehouses.splice(i, 1);
          continue;
        }
      }
    },
    removeLotNumbers(item) {
      //this.filters.lotNumbers.splice(this.filters.lotNumbers.indexOf(item), 1);
      for (var i = 0; i < this.filters.lotNumbers.length; i++) {
        if (
          this.filters.lotNumbers[i].id == item.id &&
          this.filters.lotNumbers[i].name == item.name
        ) {
          this.filters.lotNumbers.splice(i, 1);
          continue;
        }
      }
    },
    removeProphylaxisStatus() {
      this.filters.withProphylaxis = null;
    },
    removeRegions(item) {
      //this.filters.region.splice(this.filters.region.indexOf(item), 1);
      for (var i = 0; i < this.filters.region.length; i++) {
        if (
          this.filters.region[i].id == item.id &&
          this.filters.region[i].name == item.name
        ) {
          this.filters.region.splice(i, 1);
          continue;
        }
      }
    },
    removeCountries(item) {
      //this.filters.country.splice(this.filters.country.indexOf(item), 1);
      for (var i = 0; i < this.filters.country.length; i++) {
        if (
          this.filters.country[i].id == item.id &&
          this.filters.country[i].name == item.name
        ) {
          this.filters.country.splice(i, 1);
          continue;
        }
      }
    },
    resetFilters() {
      this.filterChanged = false;
      this.$store.commit("globalDimensionFilter/resetFilters");
      this.setGlobalFilterManufacturer();
    },
  },
  created() {
    this.isCountryReportCard =
      this.$route.name == ROUTE_NAMES.COUNTRY_REPORT_CARD;
  },
};
</script>
